import React from 'react';

import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../../../atoms/Eyebrow';
import { THorizontalCardBgV2Props } from '../types';

const Eyebrows: React.FC<
  Pick<THorizontalCardBgV2Props, 'eyebrows'>
> = props => {
  const { eyebrows } = props;

  if (!eyebrows?.length) {
    return null;
  }

  return (
    <div className='mb-4 flex flex-wrap gap-6'>
      {eyebrows.map((eyebrow, index) => (
        <Eyebrow
          key={`${eyebrow}-${index}`}
          size={EEyebrowSize.Medium}
          variant={EEyebrowVariant.TextTracking}
          className='text-interface-500'
        >
          {eyebrow}
        </Eyebrow>
      ))}
    </div>
  );
};

export default Eyebrows;
