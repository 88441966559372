import { cva } from 'cva';
import React from 'react';

import BlockTitle from './BlockTitle';
import { Icon } from '../../../atoms/Icon';
import {
  EIconBottomPadding,
  EIconSize,
} from '../../../atoms/Icon/Icon.constants';
import { EHorizontalCardBgV2Theme } from '../constants';
import { THorizontalCardBgV2Block } from '../types';

const descriptionCva = cva(
  'inline-flex items-start justify-start gap-2 pe-2 text-base leading-mega-loose text-interface-800 empty:hidden prose-p:pb-4 last:prose-p:pb-0 prose-a:text-brand-500  prose-li:pb-4 last:prose-li:pb-0',
  {
    variants: {
      theme: {
        [EHorizontalCardBgV2Theme.Theme1]: '',
        [EHorizontalCardBgV2Theme.Theme2]: '',
        [EHorizontalCardBgV2Theme.HorizontalCardBgV2ForexBroker]:
          '[&_.custom-li]:before:bg-interface-400',
        [EHorizontalCardBgV2Theme.HorizontalCardBgV2B2Prop]:
          '[&_.custom-li]:before:bg-interface-400',
        [EHorizontalCardBgV2Theme.HorizontalCardBgV2CryptoBroker]:
          '[&_.custom-li]:before:bg-interface-400',
        [EHorizontalCardBgV2Theme.HorizontalCardBgV2Liquidity]:
          '[&_.custom-li]:before:bg-interface-400',
        [EHorizontalCardBgV2Theme.HorizontalCardBgV2B2Trader]:
          '[&_.custom-li]:before:bg-interface-400',
      },
    },
  },
);

type THorizontalCardBgV2BlockProps = THorizontalCardBgV2Block & {
  theme: EHorizontalCardBgV2Theme;
};

const Block: React.FC<THorizontalCardBgV2BlockProps> = props => {
  const { icon, description, title, theme } = props;

  return (
    <div className='max-w-[22.5rem]'>
      <Icon
        bottomPadding={EIconBottomPadding.Yes}
        icon={icon}
        size={EIconSize.Xl}
      />

      <BlockTitle title={title} />

      {description && (
        <div className={descriptionCva({ theme })}>{description}</div>
      )}
    </div>
  );
};

export default Block;
