import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';
import { THorizontalCardBgV2TypeB } from '../types';

const SmallImagesAndTags: React.FC<
  Pick<THorizontalCardBgV2TypeB, 'smallImages' | 'tags'>
> = props => {
  const { smallImages, tags } = props;

  return (
    <div className='inline-flex w-full flex-wrap items-start justify-start gap-4 self-start rounded-3xl px-8 pb-8 xl:min-w-[334px] xl:max-w-[334px] xl:pt-8 2xl:min-w-[400px] 2xl:max-w-[400px]'>
      {!!smallImages?.length &&
        smallImages.map((smallImage, index) => (
          <ImageWithPlaceholder
            {...smallImage}
            height={56}
            key={`${smallImage.src}-${index}`}
          />
        ))}

      {/* TODO: change to TitleCard */}
      {!!tags?.length &&
        tags.map((tag, index) => (
          <div
            className='inline-flex h-14 min-w-[219px] flex-col items-center justify-center rounded-2xl bg-surface-100 px-6 py-4'
            key={`${tag}-${index}`}
          >
            <h3 className='text-base font-semibold leading-tight text-interface-1000'>
              {tag}
            </h3>
          </div>
        ))}
    </div>
  );
};

export default SmallImagesAndTags;
