export enum EHorizontalCardBgV2Type {
  A = 'A',
  B = 'B',
}

export enum EHorizontalCardBgV2Theme {
  Theme1 = 'Theme1',
  Theme2 = 'Theme2',
  HorizontalCardBgV2ForexBroker = 'HorizontalCardBgV2ForexBroker',
  HorizontalCardBgV2B2Prop = 'HorizontalCardBgV2B2Prop',
  HorizontalCardBgV2CryptoBroker = 'HorizontalCardBgV2CryptoBroker',
  HorizontalCardBgV2B2Trader = 'HorizontalCardBgV2B2Trader',
  HorizontalCardBgV2Liquidity = 'HorizontalCardBgV2Liquidity',
}
