import React from 'react';

import baseTailwindConfig from '../../../../../tailwind.config.base';
import { useBetterMediaQuery } from '../../../../hooks/useBetterMediaQuery';
import { TitlePart } from '../../../atoms/TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../../../atoms/TitlePart/TitlePart.constants';

import type { THorizontalCardBgV2Block } from '../types';

const BlockTitle: React.FC<Pick<THorizontalCardBgV2Block, 'title'>> = props => {
  const { title } = props;

  const isMd = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.md})`,
  );

  if (!title) {
    return null;
  }

  return (
    <TitlePart
      theme={ETitlePartsTheme.Dark}
      size={isMd ? ETitlePartsSize.Ml : ETitlePartsSize.M}
      position={ETitlePartsPosition.Left}
      className='pb-4'
    >
      {title}
    </TitlePart>
  );
};

export default BlockTitle;
