import React from 'react';

import baseTailwindConfig from '../../../../../tailwind.config.base';
import { useBetterMediaQuery } from '../../../../hooks/useBetterMediaQuery';
import { TitlePart } from '../../../atoms/TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../../../atoms/TitlePart/TitlePart.constants';

import type { THorizontalCardBgV2Props } from '../types';

const Subtitle: React.FC<
  Pick<THorizontalCardBgV2Props, 'subtitle'>
> = props => {
  const { subtitle } = props;

  const isMd = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.md})`,
  );

  if (!subtitle) {
    return null;
  }

  return (
    <TitlePart
      theme={ETitlePartsTheme.Light}
      size={isMd ? ETitlePartsSize.M : ETitlePartsSize.S}
      position={ETitlePartsPosition.Left}
      className='pe-[4.5rem] pt-4'
    >
      <h4 className='text-interface-700'>{subtitle}</h4>
    </TitlePart>
  );
};

export default Subtitle;
